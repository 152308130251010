import queryClient from '#/api/query-client';
import { useDelete, useGet, usePost, usePut } from '#/api/reactQuery';
import { DigitalAssetsList, FormValues } from '#/types/digitalAssets';

const BASE_URL = 'digital-footprint/digital-assets';

export const DigitalAssetsEndpoints = {
  getDigitalAssets: () => `${BASE_URL}/`,
  addDigitalAsset: () => `${BASE_URL}/`,
  updateDigitalAsset: (id: number | string | undefined) => `${BASE_URL}/${id}/`,
  deleteDigitalAsset: (id: number | string | undefined) => `${BASE_URL}/${id}/`,
  exportDigitalAssets: () => `${BASE_URL}/export-list/`,
};

export const invalidateDigitalAssetsQueries = {
  getDigitalAssets: () => {
    queryClient
      .invalidateQueries(DigitalAssetsEndpoints.getDigitalAssets())
      .then((r) => r);
  },
  exportDigitalAssets: () => {
    queryClient.removeQueries(DigitalAssetsEndpoints.exportDigitalAssets());
  },
};

export const useGetDigitalAssets = (
  page: number,
  invitation_id?: number | string,
  search?: string | undefined,
  asset_type?: string | undefined,
  ordering?: string | undefined,
  enabled?: boolean
) =>
  useGet<DigitalAssetsList>(
    DigitalAssetsEndpoints.getDigitalAssets(),
    {
      page,
      search,
      asset_type,
      ordering,
      invitation_id,
    },
    {
      enabled,
    }
  );

export const useAddDigitalAsset = () =>
  usePost<FormValues, FormValues>(DigitalAssetsEndpoints.addDigitalAsset());

export const useUpdateDigitalAsset = (id: number | string | undefined) =>
  usePut<FormValues, FormValues>(DigitalAssetsEndpoints.updateDigitalAsset(id));

export const useDeleteDigitalAsset = (id: number | string | undefined) =>
  useDelete(DigitalAssetsEndpoints.deleteDigitalAsset(id));

export const useExportDigitalAssets = (
  enabled: boolean,
  invitation_id?: number | string
) =>
  useGet<{ file_url: string }>(
    DigitalAssetsEndpoints.exportDigitalAssets(),
    {
      invitation_id,
    },
    {
      enabled,
    }
  );
